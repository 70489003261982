import React from 'react'
import { graphql } from "gatsby"
import { TransitionState } from "gatsby-plugin-transition-link"
import posed from "react-pose"
import { motion } from "framer-motion"

import Layout from "../components/Layout"
import Navigation from '../components/Navigation'

const PageFade = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
})

const pageAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1
  }
}

const ErrorPage = (props) => {

  const data = props.data.prismicError.data

  return (
    <Layout props={props} type={props.data.prismicError.type}>
      <TransitionState>
        {({ transitionStatus }) => (
          <PageFade
            pose={
              ["entering", "entered"].includes(transitionStatus)
                ? "visible"
                : "hidden"
            }
          >
            <motion.div
              className="error-page wrapper is-fluid"
              initial="hidden"
              animate="visible"
              variants={pageAnimation}>
              <div className="columns is-mobile">
                <div className="column is-10 is-offset-1">
                  <div className="content has-text-centered">
                    <h1 className="error-page__title title is-size-2 is-size-1-desktop">
                      {data.title.text}
                    </h1>
                    <a
                      className="button button--grey is-rounded is-size-5-tablet is-size-4-widescreen"
                      href={'/'}
                      target="_self">
                      <span>{data.url_copy.text}</span>
                      <span className="icon icon--black">
                        <i></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </motion.div>
            <Navigation template="mini" locale={props.pageContext.lang} />
          </PageFade>
        )}
      </TransitionState>
    </Layout>
  )
}

export const errorData = graphql`
  query ErrorQuery($lang: String!) {
    prismicError(lang: {eq: $lang}) {
      data {
        title {
          text
        }
        copy {
          text
        }
        url_copy {
          text
        }
      }
      type
    }
  }
`

export default ErrorPage
